.App {
  margin: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.listItem {
  background-color: #d5dde9;
  margin-bottom: 10px !important;
  cursor: pointer;
}
.listTop {
  color: white;
  background-color: #2d3e57;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 10px !important;
}
.button{
  background-color: #2d3e57;
}
.ivTop {
  background-color: #d5dde9;
  font-weight: bold;
  padding: 5px;
}
.ivBox {
  border: 1px solid lightblue;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
